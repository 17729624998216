













import Vue from 'vue';
import Toast from "@/components/util/Toast.vue";

export default Vue.extend({
    components: {
        Toast,
    }
    
});







































































import Vue from 'vue';
import { ApiHttpClient } from '@/globals';

interface IData {

}

export default Vue.extend({
    computed: {
        heroImgUrl(): string {
            // return ApiHttpClient.defaults.baseURL + "/server/na/files/Loading_SaintHaven_New_Limbo01.jpg";
            return ApiHttpClient.defaults.baseURL + "/server/na/files/Loading_14761.jpg";
        },
    },
});
